import React from 'react'
import {useState, useEffect} from "react";

const url = "/Umbraco/Api/Works/GetAllCategoriesWithWorks";
const WorkContext = React.createContext();

const WorkProvider = (props) => {

    //init ren tekst cat indlæs init med querystrings please
    const [categories, setCategories] = useState([])
    const [works, setWorks] = useState([])
    const [YearRange, setYearRange] = useState([])
    const [Loading, setLoading] = useState(true)
    const [WorksOnView, setWorksOnView] = useState([])
    const [CategoriesOnView, setCategoriesOnView] = useState([])

//omskriv api
    const fetchWorksData = async () => {
        setLoading(true);
        const WorksData = await fetch(url)
        const CategoriesWithWorks = await WorksData.json()
        let arr = []
        CategoriesWithWorks.map(cat => {
            arr = arr.concat(cat.WorksInCategory)
        })

        let yearend = 0;
        let yearstart = 0;
        //array reducer
        let reduceStart = (accumulator, work) => {
            if (work.ProductionYear < accumulator.ProductionYear)
                accumulator.ProductionYear = work.ProductionYear

            return accumulator
        }
        let reduceEnd = (accumulator, work) => {
            if (work.ProductionYear > accumulator.ProductionYear) {
                accumulator.ProductionYear = work.ProductionYear
            }
            return accumulator
        }
        yearend = arr.reduce(reduceEnd).ProductionYear;
        yearstart = arr.reduce(reduceStart).ProductionYear;
        let years = [yearstart, yearend];
        let arrCats = [];
        CategoriesWithWorks.map(cat => {
            arrCats.push(cat.CategoryName)
        })

        setCategoriesOnView(CategoriesWithWorks)
        setYearRange(years)
        setWorks(arr)
        setWorksOnView(arr);
        setCategories(arrCats)
        return WorksData;
    }


    useEffect(() => {
        fetchWorksData().then((e) => {
            //setLoading(false);
        });

    }, [])


    return (
        <WorkContext.Provider value={{
            categories,
            works,
            WorksOnView,
            setWorksOnView,
            CategoriesOnView,
            YearRange,
            Loading,
            setLoading,
        }}>
            {props.children}
        </WorkContext.Provider>
    )
}
const WorksPageConsumer = WorkContext.Consumer
export {WorkProvider, WorksPageConsumer, WorkContext}
