import React, {useContext} from 'react'
import {useState, useEffect} from "react";
import {WorkProvider, WorkContext} from "./mk-context";


const SearchContext = React.createContext();
const getUrlParm = (paramName) => {
    let usp = new URLSearchParams(window.location.search);
    let result = usp.get(paramName);
    if (result != null) {
        let decodResult =decodeURIComponent(result);
        if (decodResult === "" || decodResult.length === 0){
            return 0;
        }else{
            return decodResult;
        }
    } else {
        return 0;
    }
}
const SearchProvider = (props) => {
    const {categories} = useContext(WorkContext)
    const [search, setSearch] = useState("")
    const {works} = useContext(WorkContext)
    const {CategoriesOnView} = useContext(WorkContext)
    const {WorksOnView, setWorksOnView} = useContext(WorkContext)
    const {YearRange} = useContext(WorkContext)
    const {Loading, setLoading} = useContext(WorkContext)
    const [ActiveWork, setActiveWork] = useState()
    const [Position, setPosition] = useState(5)
    const [UserIsSearching, setUserIsSearching] = useState(false)
    const [NavigateByCategory, setNavigateByCategory] = useState(false)
    const [ShowCategoryView, setShowCategoryView] = useState(true)
    const [filters, setFilters] = useState({
        years: [],
        categories: [],
        works: [],
        searchTerm: "",
        pos: 5,
        CatViewIsParent:false
    })

    const handleYearRangeSlider = (e) => {
        let newfilters = {...filters}
        newfilters.years = e;
        newfilters.pos = 5;
        setFilters(newfilters)
        setShowCategoryView(false)
        setUserIsSearching(true)
        setNavigateByCategory(false)
    }
    const showHideCategoryView =() =>{
        let termTest = getUrlParm("term");
        let catTest =  JSON.parse(getUrlParm("categories").toString())
        let yearsTest = JSON.parse(getUrlParm("years").toString())
        let posTest = JSON.parse(getUrlParm("position").toString())
        let parentIsCatView = JSON.parse(getUrlParm("parentIsCatView").toString());
        if (termTest === 0 && catTest === 0 && yearsTest === 0 && posTest === 0 && parentIsCatView == false) {
            setShowCategoryView(true);
            setUserIsSearching(false)
            setNavigateByCategory(false)

        }else{

            if (parentIsCatView === true){
                setNavigateByCategory(true)
            }else{
                setNavigateByCategory(false)
            }
            setShowCategoryView(false)
            setUserIsSearching(true)

        }
    }

    const handleCategoryCheck = (e) => {
        let catName = e.target.value;
        let isCatChecked = e.target.checked;
        let newFilters = {...filters};
        let catArr = [...newFilters.categories];
        if (isCatChecked) {
            if (!catArr.includes(catName)) {
                catArr.push(catName)
            }
        } else {
            let index = catArr.indexOf(catName)
            catArr.splice(index, 1)

        }
        setPosition(5);
        newFilters.pos = 5;
        newFilters.categories = catArr;
        setFilters(newFilters);

    }

    const handleCategoryClick = (category) => {
        let newFilters = {...filters}
        newFilters.pos = 5;
        let newArr = [];
        newArr.push(category.CategoryName);

        newFilters.categories = newArr;
        newFilters.CatViewIsParent = true;
        setFilters(newFilters);
        setNavigateByCategory(true);
        setShowCategoryView(false)
    }
    const handleWorkClick = (work) => {
        setLoading(true)
        setActiveWork(work)

    }
    const filterTerm = (work) => {

        let term = filters.searchTerm;
        let titleContainsTerm: boolean;
        if (term != "") {
            titleContainsTerm = work.WorkTitle.toLowerCase().includes(term.toLocaleLowerCase());

        } else {
            titleContainsTerm = true;
        }
        return titleContainsTerm;
    }
    const filterCategory = (work) => {
        let workIsInCat: boolean;
        if (filters.categories.length !== 0) {

            workIsInCat = filters.categories.includes(work.Category)
        } else {
            workIsInCat = true
        }
        return workIsInCat;
    }
    const filterYears = (work) => {
        let answer = false;
        if (filters.years.length === 0) {

            answer = work.ProductionYear >= YearRange[0] && work.ProductionYear <= YearRange[1];
        } else {

            answer = work.ProductionYear >= filters.years[0] && work.ProductionYear <= filters.years[1];
        }

        return answer;
    }
    const handleFiltering = () => {
        let arr = [...works]
        setWorksOnView(arr.filter(filterTerm).filter(filterCategory).filter(filterYears));
        //query string creation
        let search = new URLSearchParams(window.location.search)
        let arrStr = encodeURIComponent(JSON.stringify(filters.categories));
        let yeaArr = encodeURIComponent(JSON.stringify(filters.years));
        let position = encodeURIComponent(JSON.stringify(filters.pos));
        let parentIsCatView = encodeURIComponent(JSON.stringify(filters.CatViewIsParent));
        if (filters.categories.length !== 0 || filters.years.length !== 0 || filters.searchTerm.length !== 0 || filters.pos > 6) {
            search.set("term", filters.searchTerm)
            search.set("categories", arrStr);
            search.set("years", yeaArr)
            search.set("position", position)
            search.set("parentIsCatView", parentIsCatView)
            history.replaceState(history.state, null, "/works?" + search.toString())
            setUserIsSearching(true)
        } else {
            history.replaceState(history.state, null, "/works")

        }
        showHideCategoryView();

    }
    const handleClickBack = (e) =>{
        console.log("user is search check in handleclick back", UserIsSearching)
        let parentIsCatView = getUrlParm("parentIsCatView")
        if (parentIsCatView === true) {
            console.log("lol");
            setShowCategoryView(true)
        }

    }
    const handleLoadMoreClick = (pos) => {
        let newfilters = {...filters}
        newfilters.pos = pos;
        setFilters(newfilters)
    }
    const handleSearchChange = (e) => {
        if (!UserIsSearching) {
            setShowCategoryView(false)
            setUserIsSearching(true)
            setNavigateByCategory(false)
        }

        let newfilters = {...filters}
        newfilters.searchTerm = e.target.value;
        newfilters.pos = 5;
        setFilters(newfilters)

    }
    useEffect(() => {
        let newFilters = {...filters}
        let termTest = getUrlParm("term") === 0 ? "" : getUrlParm("term");
        let catTest = JSON.parse(getUrlParm("categories").toString())
        let yearsTest = getUrlParm("years")
        let posTest = getUrlParm("position")
        let parentIsCatView = getUrlParm("parentIsCatView")
        if (termTest !== "" || catTest != 0 || yearsTest != 0 || posTest != 0 || parentIsCatView != 0) {
            newFilters.searchTerm = termTest.toString();
            newFilters.categories = JSON.parse(getUrlParm("categories").toString());
            newFilters.years = JSON.parse(getUrlParm("years").toString())
            newFilters.pos = JSON.parse(getUrlParm("position").toString());
            newFilters.CatViewIsParent = JSON.parse(getUrlParm("parentIsCatView").toString());
            setFilters(newFilters);
            if(newFilters.CatViewIsParent){
                setNavigateByCategory(true)
                setShowCategoryView(false)
                setUserIsSearching(false)
            }else{
                setShowCategoryView(false)
                setUserIsSearching(true)
                setNavigateByCategory(false)
            }
        }
    }, [works])
    useEffect(() => {

        handleFiltering()
    }, [filters])

    useEffect(() => {
        window.onpopstate = (e) => {
            let el3 = document.getElementsByClassName("spacer");
            if (el3.length !== 0 && !ShowCategoryView){
                el3[0].classList.remove("d-none");

            }
            let cssClass = "search--inactive";
            let termTest = getUrlParm("term") === 0 ? "" : getUrlParm("term");
            let catTest = JSON.parse(getUrlParm("categories").toString())
            let yearsTest = JSON.parse(getUrlParm("years").toString())
            let posTest = JSON.parse(getUrlParm("position").toString())
            let parentIsCatView = JSON.parse(getUrlParm("parentIsCatView").toString())
            if (termTest ==="" && catTest === 0 && yearsTest === 0 && posTest === 0 && parentIsCatView === 0) {
                setFilters({
                    years: [],
                    categories: [],
                    works: [],
                    searchTerm: "",
                    pos: 5,
                    CatViewIsParent:false
                })
                setShowCategoryView(true)
                setUserIsSearching(false)
                setNavigateByCategory(false)
            } else {
                console.log("navbycat",NavigateByCategory, "ShowCat?", ShowCategoryView)
                if (NavigateByCategory){
                    setUserIsSearching(false)
                    setShowCategoryView(false)
                    setNavigateByCategory(true)

                }else{

                    setNavigateByCategory(false)
                    setShowCategoryView(false)
                }


                if (NavigateByCategory){
                    document.getElementsByClassName("xxl-container")[0].classList.add(cssClass);
                }else{
                    document.getElementsByClassName("xxl-container")[0].classList.remove(cssClass);
                }

            }
        }
    })
    return (
        <SearchContext.Provider value={{
            categories,
            handleLoadMoreClick,
            handleSearchChange,
            handleCategoryClick,
            handleWorkClick,
            handleCategoryCheck,
            handleYearRangeSlider,
            CategoriesOnView,
            WorksOnView,
            handleClickBack,
            search,
            filters,
            YearRange,
            ActiveWork,
            Loading,
            setLoading,
            Position,
            setPosition,
            UserIsSearching,
            NavigateByCategory,
            ShowCategoryView,
        }}>
            {props.children}
        </SearchContext.Provider>
    )
};
const WorksPageConsumer = SearchContext.Consumer
export {SearchProvider, WorksPageConsumer, SearchContext}
