import {WorkContext} from "../Context/mk-context";
import React, {useContext, useEffect} from "react";
import {Slider} from 'antd';
import {SearchContext} from "../Context/mk-searchprovider";

const getUrlParm = (paramName) => {
    let usp = new URLSearchParams(window.location.search);
    let result = usp.get(paramName)
    if (result != null) {
        return decodeURIComponent(result);
    } else {
        return 0
    }
}
export default function WorksSearch() {
    let cssClass = "search--inactive";
    let hideElement = "";

    const {filters, categories, YearRange, handleYearRangeSlider, handleCategoryCheck, handleSearchChange} = useContext(SearchContext)
    let toYear = YearRange[1] || 2019; // momentjs til at sætte tid
    let fromYEar = YearRange[0] || 1981;
    let defFromYear = filters.years[0] || JSON.parse(getUrlParm("years"))[0] || 1981
    let defToYEar = filters.years[1] || JSON.parse(getUrlParm("years"))[1] || 2019

    useEffect(() => {

        if (getUrlParm("term") === 0) {

            document.getElementsByClassName("xxl-container")[0].classList.add(cssClass);

        } else {
            if (getUrlParm("parentIsCatView") !== false) {
                document.getElementsByClassName("xxl-container")[0].classList.remove(cssClass);
            }
        }

        let el = document.getElementsByClassName("mk-footer");
        let el2 = document.getElementsByClassName("mk-navbar");
        el[0].classList.remove("d-none")
        el2[0].classList.remove("d-none")
    })
    return (

        <div id="works-searchbox" className={`mk-card__searchbox works-category__header card m-4`}
             style={{flex: "1 1 auto"}}>
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <a href={"/works"}><h4>Works</h4></a>
                        <label>Title</label>
                        <input id="titleTextField"
                               defaultValue={filters.searchTerm}
                               onChange={(e) => {
                                   handleSearchChange(e);
                               }}
                               type="textfield" className="works-title-input" name="title"
                               placeholder="Search by title"/>
                    </div>
                    <div className="form-group" style={{marginBottom: "5px"}}>
                        <label>Year</label>
                    </div>
                    <div className="form-group" style={{marginBottom: "8.5px"}}>

                        <Slider
                            defaultValue={[defFromYear, defToYEar]}
                            min={fromYEar}
                            max={toYear}
                            range
                            onAfterChange={handleYearRangeSlider}
                        />

                        <span className="range-slider-min float-left">{YearRange[0]}</span>
                        <span className="range-slider-max float-right">{YearRange[1]}</span>
                        <div className="clearfix">
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="form-group">
                        <label>Category</label>
                        <div className="clearfix"></div>
                        <React.Fragment>
                            <div className="formcheck-wrapper">
                                {categories.map((item, index) => (

                                    <div key={item.replace(/\s+/g, '') + index}
                                         className="form-check workitem-checkmark m-1">
                                        <input
                                            className="category-checkbox form-check-input"
                                            id={"chck_" + item.replace(/\s+/g, '') + index}
                                            key={item.replace(/\s+/g, '') + index}
                                            type="checkbox"
                                            checked={filters.categories.includes(item)}
                                            value={item}
                                            onChange={(e) => {
                                                handleCategoryCheck(e);

                                            }}

                                        />
                                        <label className="form-check-label"
                                               htmlFor={"chck_" + item.replace(/\s+/g, '') + index}>
                                            {item}
                                        </label>

                                    </div>

                                ))}
                            </div>
                        </React.Fragment>


                    </div>
                </div>
            </div>
        </div>
    );
}

