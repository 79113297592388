import React, {useContext, useEffect} from "react";
import {WorkContext} from "../Context/mk-context";
import {SearchContext} from "../Context/mk-searchprovider";
import {
    Link
} from "react-router-dom";


export default function Categories() {
    const {handleCategoryClick, ShowCategoryView,CategoriesOnView} = useContext(SearchContext)

//<a onClick={handleSubmit} href={"/category="+category.CategoryUrl} className="mk-cat-link">
    return (
        <React.Fragment>


            {CategoriesOnView.map((category, index) => (
                <div key={category.dataCategoryName}
                     className={`mk-cat-card card m-4 firstView ${ShowCategoryView ? "" : "mk-hidden"}`}
                     style={{flex: "auto", maxWidth: "300px"}}>
                    <Link className="mk-cat-link" to="/works/?term" onClick={(e) => {
                        handleCategoryClick(category)
                    }} data-cat={category.CategoryName}>
                        <img src={category.CategoryImageUrl} data-cat={category.CategoryName}
                             className="mk-card-img card-img-top" alt="Category image"/> </Link>

                    <div className="mk-card-body card-body bg-transparent">

                        <p className="mk-card-text card-text"><Link to="/works/?" onClick={(e) => {

                            handleCategoryClick(category)
                        }} data-cat={category.CategoryName}>{category.CategoryName}<span
                            className="ml-5"><img src={"/assets/pil.png"}
                                                  alt={"arrow"} className="link-arrow"/></span></Link></p>

                    </div>
                </div>
            ))}

        </React.Fragment>
    );

}
