import React, {useContext} from 'react';
import {WorkContext, WorkProvider} from "./Context/mk-context";
import {SearchProvider} from "./Context/mk-searchprovider";
import WorksPage from "./Views/WorksPage";


function App() {


    return (
        <WorkProvider>
            <SearchProvider>
                <WorksPage/>
            </SearchProvider>
        </WorkProvider>
    )
}

export default App;
