import React, {useContext, useEffect} from 'react';
import WorksSearch from "./WorksSearch";
import WorksList from "./WorksList";
import WorksDetail from "./WorksDetail";
import Categories from "./Categories";
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import {SearchContext} from "../Context/mk-searchprovider";

function WorksPage() {
    const {NavigateByCategory, ShowCategoryView, UserIsSearching, WorksOnView} = useContext(SearchContext)
    useEffect(()=>{
        let cssClass = "search--inactive";
        if (UserIsSearching) {
            document.getElementsByClassName("xxl-container")[0].classList.remove(cssClass);

        }


    },[UserIsSearching])
    return (
        <BrowserRouter>


            <Switch>
                {/* Wrap ind i et nyt view/screen, så du kan bruge context */}
                <Route exact path={"/works/"}>
                    <div className="workform-container container">
                        <div className="row">
                            {
                                ShowCategoryView ? "" :  <div className=" col-sm-12 col-md-12 col-lg-1 col-xl-1"> </div>
                            }

                            <div className={`col-xl-${ShowCategoryView? "11" : "10"} col-lg-10 col-md-12 col-sm-12`}>
                                <div
                                    className={`mk-cards card-deck load-more__container xxl-container ${UserIsSearching ? "search--inactive" : ""} ${!ShowCategoryView ? "search--inactive" : ""}`}>

                                    {
                                        NavigateByCategory ? null : <WorksSearch/>
                                    }

                                    {
                                        ShowCategoryView ? <Categories/> : ""
                                    }
                                    {
                                        NavigateByCategory ? (
                                            <>
                                                <div id={"card-header"}
                                                     className="works-category__header mk-work-card  m-4 card mk-show"
                                                     style={{
                                                         flex: "auto",
                                                         order: 0,
                                                         maxHeight: "400px",
                                                         maxWidth: "400px",
                                                         display: "block"
                                                     }}>
                                                    <div className="header__inner"><a href="/works"><h2
                                                        className="category-headline"><img
                                                        src="/assets/pil-left.png" className="back-arrow-new"/>{
                                                        WorksOnView.map((item, index) => {

                                                            if (index == 0) {
                                                                return item.Category;

                                                            }
                                                        })
                                                    }</h2></a></div>
                                                    <div className="header__fill"></div>
                                                </div>

                                                <WorksList/>
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    ShowCategoryView ? "" : <WorksList/>
                                                }
                                            </>
                                        )
                                    }


                                </div>


                            </div>
                            <div className=" col-sm-12 col-md-12 col-lg-1 col-xl-1"> </div>
                        </div>
                    </div>
                </Route>
                <Route path={"/works/:id"}>
                    <WorksDetail/>
                </Route>

            </Switch>
        </BrowserRouter>
    )
}

export default WorksPage;
