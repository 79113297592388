import React, {useContext, useEffect} from "react";
import {MKContext} from "../Context/mk-context";
import {
    Link
} from "react-router-dom";
import WorksDetail from "./WorksDetail";
import {SearchContext} from "../Context/mk-searchprovider";


export default function WorkList() {
    const {WorksOnView, handleWorkClick, setLoading, UserIsSearching, Position, setPosition, handleLoadMoreClick} = useContext(SearchContext)

    const updateView = () => {
        let hideAll = Array.prototype.slice.call(document.querySelectorAll(".load-more__item"));
        hideAll.map(work => {
            work.classList.remove("mk-show")
        })
        let usp = new URLSearchParams(window.location.search);
        let result = usp.get("position");
        let workItems = Array.prototype.slice.call(document.querySelectorAll(".load-more__item:not(.mk-show)"));
        if (result !== null) {
            let position = JSON.parse(decodeURIComponent(result));
            workItems.slice(0, position).map((item, index) => {
                item.classList.add("mk-show");
            });
        } else {
            workItems.slice(0, 5).map((item, index) => {
                item.classList.add("mk-show");
            });
        }

        let loadmorebtn = document.getElementsByClassName("load-more-button__container")[0];
        if (document.querySelectorAll(".load-more__item:not(.mk-show)").length !== 0) {
            loadmorebtn.classList.remove("d-none")
        } else {
            loadmorebtn.classList.add("d-none")
        }

    }
    const handleLoadMoreInsideClick = (e) => {

        e.preventDefault();
        let scrollPos = $(window).scrollTop();
        let loadmorebtn = document.getElementsByClassName("load-more-button__container")[0];

        let loadItems = Array.from(document.querySelectorAll(".load-more__item:not(.mk-show)"));
        let newPos = Position + 5;
        handleLoadMoreClick(newPos);
        setPosition(newPos)

        loadItems = loadItems.slice(0, 5);
        loadItems.map((item, index) => {
            item.classList.add("mk-show")
        })
        if (document.querySelectorAll(".load-more__item:not(.mk-show)").length === 0) {
            loadmorebtn.classList.add("d-none");
        }
        $(window).scrollTop(scrollPos);
    }
    const removeFooterAndNavBar = () => {
        let el = document.getElementsByClassName("mk-footer");
        let el2 = document.getElementsByClassName("mk-navbar");
        el[0].classList.remove("d-none")
        el2[0].classList.remove("d-none")

    };
    const imageLoaded = () => {

        setLoading(false);

    }
    useEffect(() => {
        removeFooterAndNavBar();
        updateView();
    }, [WorksOnView, UserIsSearching])
    return (

        <React.Fragment>

            {WorksOnView.map((work, index) => (


                <div key={work.WorkItemID} className="mk-work-card card m-4 load-more__item secondView"
                     style={{flex: "auto", order: index + 1, maxHeight: "400px", maxWidth: "400px"}}>
                    <Link onClick={(e) => {
                        handleWorkClick(work)
                    }}
                          to={work.WorkUrl}
                          className="mk-work-link">

                        <img src={work.WorkImageUrl} className="mk-card-img card-img-top" onLoad={imageLoaded}
                             alt={work.WorkTitle}/>


                        <p className="works-card-name link">{work.WorkTitle}<img src={"/assets/pil.png"}
                                                                                 alt={"arrow"} className="link-arrow"/>
                        </p>
                    </Link>
                </div>

            ))}

            <div className="load-more-button__container d-none" style={{order: WorksOnView.length + 200}}>
                <div className="expandable_text_block"></div>
                <a href="#" id="loadMorereact" onClick={handleLoadMoreInsideClick}
                   className="load-more__button">Load more <img
                    src="/assets/pil.png" className="link-arrow"/></a>

            </div>


        </React.Fragment>

    );

}
