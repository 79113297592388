import React, {useContext, useEffect} from "react";
import {SearchContext} from "../Context/mk-searchprovider";
import {
    Link,
    useParams
} from "react-router-dom";



export default function WorkDetail() {
    let { id } = useParams();
    const {WorksOnView, ActiveWork,handleWorkClick, Loading,setLoading,handleClickBack} = useContext(SearchContext)
    const moveBackwards = () =>{
        let arr = [...WorksOnView];
        let pos = arr.findIndex(work => work.WorkTitle === ActiveWork.WorkTitle);
        let nxt = 0;

        if (pos != 0){
            nxt = pos -1;
        }else{
            nxt = arr.length -1

        }

        let arrayElement = arr[1]
        return arr[nxt]
    }
    const moveForward = () =>{
        let arr = [...WorksOnView];
        let pos = arr.findIndex(work => work.WorkTitle === ActiveWork.WorkTitle);
        let nxt = 0
        let arrLength = arr.length  -1;
        if (pos != arrLength){
            nxt = pos +1;
        }else{
            nxt = 0
        }
        return arr[nxt]
    }
    const imageLoaded = () => {

        setLoading(false);

    }
    useEffect(()=>{
        console.log(ActiveWork)
        if (WorksOnView.length <= 1){
            let links = document.getElementsByClassName("single-view-links");
            links[0].classList.add("d-none");
        }else{
            let links = document.getElementsByClassName("single-view-links");
            links[0].classList.remove("d-none");
        }
    let el = document.getElementsByClassName("mk-footer");
    let el2 = document.getElementsByClassName("mk-navbar");
    let el3 = document.getElementsByClassName("spacer");
    if (el3.length !== 0){
        el3[0].classList.add("d-none");
    }
    el[0].classList.add("d-none")
        el2[0].classList.add("d-none")
        mediumZoom('[data-zoomable]',
            {  background: '#000',
    })
    },[])
    return (
        <React.Fragment>
            <div>
                <div className="single-view-header-wrapper fluid-wrapper">
                    <div className="container">
                        <div className="col-md-12">
                            <div className="single-page-header">
                                <a onClick={(e) => {
                                    handleClickBack(e);
                                    history.back();
                                }} data-cat={ActiveWork.Category}>

                                <img src="/assets/pil-left.png" className="assets back-arrow"/>
                                <h1 className={""}>MICHAEL KVIUM</h1></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="single-view-content-wrapper fluid-wrapper">
                    <div className="container align-self-center">
                        <div className="row" style={{margin: "0"}}>
                            <div className="col-xl-4 col-md-4 col-12 my-auto">
                                <div className="info-container">


                                    <h3>{ActiveWork.WorkTitle}</h3>

                                    <h5>Year</h5>
                                    <span className="year">{ActiveWork.ProductionYear}</span>
                                    {ActiveWork.HeightCM === 0 ? "" : (
                                        <>
                                        <h5>Size</h5>
                                        <span data-cmheight="250" data-cmwidth="200" className="size">{ActiveWork.HeightCM} - {ActiveWork.WidthCM} cm</span>
                                        <div className=""><span className="size inches">{ActiveWork.HeightInch} - {ActiveWork.WidthInch} inches</span></div>
                                        </>
                                        )}



                                    <h5>Category</h5>
                                    <span className="category">{ActiveWork.Category}</span>


                                    <div className="text"  dangerouslySetInnerHTML={{__html:ActiveWork.WorkText}}>

                                    </div>


                                </div>
                            </div>
                            <div className="col-xl-8 col-md-8 col-12">
                                <div className={"justify-content-center"} style={{display: Loading ? "flex" : "none"}}>

                                    <img src="/assets/Spinner-1s-200px.svg" alt="loading"/>
                                </div>

                                <div style={{display: Loading ? "none" : "block"}}>

                                        <img
                                            data-zoomable
                                            src={ActiveWork.BigImage+"?width=1200"}
                                         className="image image01 img-fluid"
                                         onLoad={imageLoaded}/>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="single-view-links fluid-wrapper mt-3">
                    <div className="container">
                        <Link replace onClick={(e) => {
                            handleWorkClick(moveBackwards())
                        }}
                              to={moveBackwards().WorkUrl}
                              className="previous-link">
                            <img src="/assets/pil-left.png"
                                 alt={"Previous"}
                                 className="link-arrow-left" />
                                 Previous
                        </Link>
                        <Link replace onClick={(e) => {
                            handleWorkClick(moveForward())
                        }}
                              to={moveForward().WorkUrl}
                              className="next-link">
                            Next
                            <img src="/assets/pil.png"
                                 alt={"Previous"}
                                 className="link-arrow"
                            />

                        </Link>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );

}
