import {BrowserRouter as Router} from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
let el = document.getElementById("react-container");
if(el !== null){
ReactDOM.render(
    <React.StrictMode>

        <App/>
    </React.StrictMode>,
    document.getElementById('react-container')

);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
